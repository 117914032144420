import dayjs from 'dayjs'

export const searchFormId = 'search-checkoutPassengersPopup'

export enum SearchFormKeys {
  'adults' = 'adults',
  'arrival' = 'arrival_station',
  'children' = 'children',
  'childrenAge' = 'children_age',
  'date' = 'departure_date',
  'departure' = 'departure_station',
  'layover' = 'layover_station',
}

export const defaultFormValues = {
  [SearchFormKeys.adults]: 1,
  [SearchFormKeys.children]: 0,
  [SearchFormKeys.childrenAge]: [],
  [SearchFormKeys.date]: [dayjs().add(7, 'days'), null],
}

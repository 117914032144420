import { useAppSelector } from '@redux/hooks'
import { selectIsMobileUserAgent } from '@reduxCommonApp/commonApp.selectors'

import useMediaQuery from '@hooks/useMediaQuery/useMediaQuery'
import { mediaQueries } from '@themes/mediaQueries'

export default () => {
  const isMobileUserAgent = useAppSelector(selectIsMobileUserAgent)
  const mediaQueryIsMobile = useMediaQuery(mediaQueries.mobile)

  return isMobileUserAgent || mediaQueryIsMobile
}
